import React from 'react';
import s from "./404.module.scss"
import Image from "next/legacy/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

const ErrorPage = () => {

    const {t} = useTranslation('common')

    return (
        <div className={s.wrapper}>
            <div className={s.image}>
                <Image src={'/assets/img/404.svg'} objectFit={"cover"} layout={"fill"}
                        alt={"404"}/>
            </div>
            <div className={s.info}>
                <h1 className={s.title}>{t("Page not found")}</h1>
                <p className={s.text}>{t("Unfortunately, the page you are trying to access could not be found")}</p>
                <Link href={"/"} className={s.button}>

                    {t("Go back to the main page")}

                </Link>

            </div>
        </div>
    );
};

export default ErrorPage;
