import React from 'react';
import ErrorPageContainer from "../containers/404/404"
import Head from "next/head";
import useTranslation from "next-translate/useTranslation";

const ErrorPage = () => {

    const {t} = useTranslation('common')

    return (
        <>
            <Head>
                <title>{`${t("404 | Page not found")} - ODIN24`}</title>
                <meta name="description" content={`${t("404 | Page not found")} - ODIN24`}/>
            </Head>
            <ErrorPageContainer/>
        </>

    );
};


export default ErrorPage;
